import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import {FilterOptions, Filters, Query, QueryOption} from '@etop/models';

@Component({
  selector: 'etop-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Output() filterChanged = new Subject<Filters>();
  @Output() queryChanged = new Subject<Query>();
  @Output() customFilterChanged = new EventEmitter();

  toppings = new FormControl();
  @Input() filters: FilterOptions;
  @Input() customFilters?: FilterOptions;
  @Input() query: QueryOption;

  @Input() topshipFilter = false;

  preFilter: Filters;

  constructor() {
  }

  ngOnInit() {
    if (this.filters) {
      this.toppings.setValue(this.filters.filter(filter => filter.show));
    }
    this.preFilter = this.filters?.map(f => ({
      name: f.name,
      op: f.operator,
      value: f.value
    }));
  }

  get availableFilters() {
    return this.filters && this.filters.filter(filter => !filter.fixed).concat(this.customFilters ?? []);
  }

  changeSelect(options: FilterOptions) {
    this.filters.forEach(filter => filter.show = false);
    options.forEach(filter => filter.show = true);
    // this.filters.forEach(filter => {
    //   if (!filter.show) { filter.value = ''; }
    // });
    
    if (options.filter(filter => filter.meta == 'custom')?.length != options.length) {
      this.onSubmitFilter();
    }
    this.customFilterChanged.emit(options.filter(filter => filter.meta == 'custom'));
  }

  public onSubmitFilter(multiple = false) {
    if(this.validateFilter()) {
      const filters: Filters = this.filters.filter(f => !!f.value).map(f => ({
        name: f.name,
        op: f.operator,
        value: multiple ? f.value.toString() : f.value
      }));
      this.preFilter = filters;
      this.filterChanged.next(filters);
    }
  }

  public onSubmitQuery() {
    const query: Query = {
      name: this.query.value
    };
    this.queryChanged.next(query);
  }

  validateFilter() {
    let isDiff = false;
    for (let i = 0; i < this.filters.length; i++) {
      const filter = this.filters[i];
      const prefilter = this.preFilter?.find(preFilter => preFilter.name === filter.name);
      if (!!filter?.value || filter?.value != prefilter?.value) {
        isDiff = prefilter?.value !== filter?.value;
        if (isDiff) {
          break;
        }
      }
    }
    return isDiff;
  }

}
